<template>
  <div id="email" v-cloak v-loading.fullscreen.lock="loading">
    <div class="container">
      <div class="w804">
        <h1 class="titH">
          <i></i>
          <span>
            {{ $t("email.manage") }}
          </span>
        </h1>
        <div class="email-out">
          <div class="email-inner">
            <div class="form-item">
              <div>
                <span class="tit-s">{{ $t("email.protocol") }}： IMAP</span>
                <div v-if="is_init">
                  <span class="tit-s">{{ $t("email.type") }}：</span>
                  <el-select
                    v-model="emailType"
                    size="mini"
                    filterable
                    class="select-email"
                    @change="changeType"
                  >
                    <el-option
                      v-for="(item, index) in email_type"
                      :key="item"
                      :label="item"
                      :value="index"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="mt20">
                <span class="tit-s">{{ $t("email.email") }}：</span>
                <el-input
                  v-model="emailForm.email"
                  size="mini"
                  class="inputW"
                  v-if="is_init"
                >
                </el-input>
                <span v-else>{{ emailForm.email }}</span>
              </div>
              <div class="mt20">
                <span class="tit-s">{{ $t("email.newPassword") }}：</span>
                <el-input
                  v-model="emailForm.password"
                  type="password"
                  size="mini"
                  class="inputW"
                  v-if="show_password || is_init"
                ></el-input>
                <span v-else>
                  <span class="psw-span">{{ emailForm.password }}</span>
                  <el-button @click="changePassword" size="mini" type="text">
                    {{ $t("email.changePassword") }}
                  </el-button>
                </span>
              </div>
              <div class="setting-box mt20">
                <div class="flex-between flex-a-center">
                  <div class="w50">
                    <span class="tit-s">
                      {{ $t("email.receivingServer") }}：
                    </span>
                    <el-input
                      v-model="emailForm.getAddr"
                      size="mini"
                      class="inputW"
                    ></el-input>
                  </div>
                  <div class="w50">
                    <span class="tit-s"> {{ $t("email.port") }}： </span>
                    <el-input
                      v-model="emailForm.getPort"
                      size="mini"
                      class="inputW"
                    ></el-input>
                  </div>
                </div>
                <div class="flex-between mt20 flex-a-center">
                  <div class="w50">
                    <span class="tit-s">
                      {{ $t("email.sendingServer") }}：
                    </span>
                    <el-input
                      v-model="emailForm.sendAddr"
                      size="mini"
                      class="inputW"
                    ></el-input>
                  </div>
                  <div class="w50">
                    <span class="tit-s"> {{ $t("email.port") }}： </span>
                    <el-input
                      v-model="emailForm.sendPort"
                      size="mini"
                      class="inputW"
                    ></el-input>
                  </div>
                </div>
                <div class="flex-between mt20 flex-a-center">
                  <div class="w50">
                    <span class="tit-s"> ssl： </span>
                    <el-switch
                      v-model="emailForm.Secure"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      :active-text="$t('yes')"
                      :inactive-text="$t('no')"
                    >
                    </el-switch>
                  </div>
                </div>
                <div class="flex-between mt20 flex-a-center">
                  <div class="w50">
                    <span class="tit-s"> {{ $t("email.node") }}： </span>
                    <el-radio-group v-model="emailForm.Domestic">
                      <el-radio :label="true">
                        {{ $t("email.cnServer") }}
                      </el-radio>
                      <el-radio :label="false">
                        {{ $t("email.ukServer") }}
                      </el-radio>
                    </el-radio-group>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-item" v-if="!is_init">
              <zc-icon-btn
                :content="$t('email.change')"
                btn_type="primary"
                btn_class="form_button"
                icon="icon--zhongzhi"
                @click="changeEmail"
              ></zc-icon-btn>
              <zc-icon-btn
                :content="$t('email.remove')"
                btn_type="danger"
                btn_class="form_button"
                icon="icon-qingkong"
                @click="delEmail"
              ></zc-icon-btn>
              <!-- <el-button type="primary" @click="changeEmail" size="mini">
                                <tags:lang>更改邮箱</tags:lang>
                            </el-button>
                            <el-button type="danger" @click="delEmail" size="mini">
                                <tags:lang>解除邮箱</tags:lang>
                            </el-button> -->
            </div>
            <div class="form-item">
              <div class="flex-between mt68 flex-a-start block-box">
                <!--                                <div class="w50">-->
                <!--                                    <h2 class="signature-span">-->
                <!--                                        <tags:lang>签名</tags:lang>-->
                <!--                                    </h2>-->
                <!--                                    <div v-for="(item,index) in emailForm.sign_desc" class="mt10">-->
                <!--                                        <el-input size="mini" class="form-input desc-margin"-->
                <!--                                            v-model="emailForm.sign_desc[index]">-->
                <!--                                        </el-input>-->
                <!--                                        <i class="el-icon-circle-plus-outline hand m10" @click="addDesc(index)"></i>-->
                <!--                                        <i class="el-icon-remove-outline m10 hand" @click="del_desc(index)"></i>-->
                <!--                                    </div>-->
                <!--                                    <div class="mt20">-->
                <!--                                        <zc-icon-btn content="<tags:lang>新增签名条目</tags:lang>" btn_type="success" btn_class="form_button" icon="icon-xinzeng" -->
                <!--                                            @click="addDesc"></zc-icon-btn>-->
                <!--                                        &lt;!&ndash; <el-button @click="addDesc('')" size="mini" type="success">-->
                <!--                                            <tags:lang>新增签名条目</tags:lang>-->
                <!--                                        </el-button> &ndash;&gt;-->
                <!--                                    </div>-->
                <!--                                </div>-->
                <div
                  class="w50 option-box"
                  v-if="!is_init && folderList.length"
                >
                  <h2 class="signature-span">
                    {{ $t("email.folder") }}
                  </h2>
                  <el-checkbox
                    :indeterminate="isIndeterminate"
                    v-model="checkAll"
                    class="mt10"
                    @change="handleCheckAllChange"
                  >
                    {{ $t("email.all") }}
                  </el-checkbox>
                  <div style="margin: 10px 0"></div>
                  <el-checkbox-group
                    v-model="emailForm.folder"
                    @change="handleCheckedChange"
                    class="all-option"
                  >
                    <el-checkbox
                      v-for="(item, index) in folderList"
                      :label="item"
                      :key="index"
                      class="mb10"
                    >
                      {{ item }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
          </div>
          <div class="submit-box flex-center">
            <zc-icon-btn
              :content="$t('cancel')"
              btn_type="danger"
              btn_class="big-btn mr60"
              icon="icon-quxiao"
              v-if="(is_init || show_password) && oldEmailForm.email"
              @click="cancel"
            ></zc-icon-btn>
            <zc-icon-btn
              :content="$t('submit')"
              btn_type="primary"
              btn_class="big-btn"
              icon="icon-tijiao"
              @click="submit"
            ></zc-icon-btn>
            <!-- <el-button type="danger" @click="cancel" size="mini" class="big-btn mr60"
                            v-if="(is_init || show_password) && oldEmailForm.email">
                            <tags:lang>cancel</tags:lang>
                        </el-button> -->
            <!-- <el-button type="primary" @click="submit" size="mini" class="big-btn">
                            <tags:lang>submit</tags:lang>
                        </el-button> -->
          </div>
          <el-row class="row-editor">
            <h2 class="signature-span" style="margin: 20px 0; text-align: left">
              {{ $t("email.sign") }}
            </h2>
            <el-col :span="24" class="col-editor">
              <div id="editor"></div>
            </el-col>
            <zc-icon-btn
              :content="$t('submit')"
              btn_type="success"
              btn_class="big-btn"
              icon="icon-tijiao"
              @click="saveEmailSign"
            ></zc-icon-btn>
          </el-row>
        </div>
      </div>
      <div class="w804">
        <h1 class="contact-titH">
          <span>
            {{ $t("email.contacts") }}
          </span>
          <zc-icon-btn
            :content="$t('email.newContact')"
            btn_type="primary"
            btn_class="search_button"
            icon="icon-xinzeng"
            @click="addContact"
          ></zc-icon-btn>
          <!-- <el-button type="primary" @click="addContact" size="mini">
                        <tags:lang>新增联系人</tags:lang>
                    </el-button> -->
        </h1>
        <el-table :data="contactData" width="100%" class="group-table">
          <el-table-column
            prop="email"
            :label="$t('email.contactEmail')"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column prop="desc" :label="$t('CrmCus.desc')" width="250">
          </el-table-column>
          <el-table-column :label="$t('handle')" width="250">
            <template slot-scope="scope">
              <zc-icon-btn
                :content="$t('edit')"
                btn_type="primary"
                btn_class="form_button"
                icon="icon-bianji"
                @click="editContact(scope.$index)"
              ></zc-icon-btn>
              <zc-icon-btn
                :content="$t('delete')"
                btn_type="danger"
                btn_class="form_button"
                icon="icon-qingkong"
                @click="delContact(scope.row.id)"
              ></zc-icon-btn>
              <!-- <el-button type="primary" @click="editContact(scope.$index)" size="mini">
                                <tags:lang>edit</tags:lang>
                            </el-button> -->
              <!-- <el-button type="danger" @click="delContact(scope.row.id)" size="mini">
                                <tags:lang>delete</tags:lang>
                            </el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
      :title="$t('email.contact')"
      :visible.sync="visible"
      width="500px"
      v-move
    >
      <div class="dialog-container">
        <span> {{ $t("email.contactEmail") }}： </span>
        <el-input v-model="contact_email" size="mini" class="inputW"></el-input>
      </div>
      <div class="dialog-container mt20">
        <span> {{ $t("email.contactDesc") }}： </span>
        <el-input v-model="contact_desc" size="mini" class="inputW"></el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="danger" @click="visible = false" size="mini">
          {{ $t("cancel") }}
        </el-button>
        <el-button type="primary" @click="visibleSub" size="mini">
          {{ $t("submit") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import E from "wangeditor";
import ZcIconBtn from "@/components/ZcIconBtn.vue";
import { toMessage } from "@/utils/message";
import "@/plugins/move";
export default {
  components: {
    ZcIconBtn,
  },
  name: "Email",
  data() {
    return {
      loading: true,
      emailForm: {},
      is_init: true,
      visible: false,
      show_password: false,
      emailType: 0,
      email_type: [
        "yandex.com",
        "163.com",
        "yeah.net",
        "cnprussia.ru",
        this.$t("other"),
      ],
      contact_email: "",
      contact_desc: "",
      contact_id: "",
      contactData: [],
      oldEmailForm: {},
      folderList: [],
      sendData: {},
      isIndeterminate: false,
      checkAll: false,
    };
  },
  methods: {
    //获取联系人
    getContacts() {
      this.axios
        .post("/home/Index/getEmailContacts")
        .then((res) => res.data)
        .then((res) => {
          this.contactData = res.data;
        })
        .catch(() => {});
    },
    //获取信息
    getEmailInfo() {
      this.loading = true;
      this.axios
        .post("/home/Index/getEmailInfo")
        .then((res) => res.data)
        .then((res) => {
          this.loading = false;
          this.folderList = res.data.folderList;
          delete res.data.folderList;
          res.data.folder = res.data.folder.filter((item) => {
            return this.folderList.indexOf(item) >= 0;
          });
          if (this.folderList.length) {
            this.checkAll = res.data.folder.length === this.folderList.length;
            this.isIndeterminate =
              res.data.folder.length > 0 &&
              res.data.folder.length < this.folderList.length;
          }
          res.data.Secure = res.data.Secure ? true : false;
          res.data.Domestic = res.data.Domestic ? true : false;
          this.oldEmailForm = JSON.parse(JSON.stringify(res.data));
          this.emailForm = res.data;
          if (res.status != 1) {
            this.changePassword();
            this.$message(toMessage("error", this.$t("email.error")));
            return;
          }
          if (!res.data.email) {
            this.is_init = true;
            this.changeType();
          } else {
            this.is_init = false;
            if (!res.data.folder.length) {
              this.$message(toMessage("warning", this.$t("email.error1")));
            }
          }
          this.editor.txt.html(res.data.sign_desc);
          this.editor.change();
        })
        .catch(() => {});
    },
    //全选
    handleCheckAllChange(val) {
      if (val) {
        // 全选
        let folder = [];
        this.folderList.forEach((element) => {
          folder.push(element);
        });
        this.emailForm.folder = folder;
      } else {
        // 全部不选
        this.emailForm.folder = [];
      }
      this.isIndeterminate = false;
    },
    //勾选
    handleCheckedChange(val) {
      let checkedCount = val.length;
      this.checkAll = checkedCount === this.folderList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.folderList.length;
    },
    //解除邮箱绑定
    delEmail() {
      this.$confirm(this.$t("email.confirmToRemove") + "?", this.$t("tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.axios
          .post("/home/Index/delEmail")
          .then((res) => res.data)
          .then((res) => {
            this.loading = false;
            if (res.status != 1) {
              this.$message(toMessage("error", res.msg));
              return;
            }
            this.$message(toMessage());
            this.getEmailInfo();
          })
          .catch(() => {});
      });
    },
    //更改邮箱
    changeEmail() {
      this.emailForm.email = "@yandex.com";
      this.emailForm.password = "";
      this.emailType = 0;
      this.emailForm.getAddr = "imap.yandex.com";
      this.emailForm.getPort = "993";
      this.emailForm.sendAddr = "smtp.yandex.com";
      this.emailForm.sendPort = "465";
      this.is_init = true;
      this.show_password = true;
    },
    //更改邮箱默认
    changeType() {
      switch (this.emailType) {
        case 0:
          this.emailForm.email = "@yandex.com";
          this.emailForm.getAddr = "imap.yandex.com";
          this.emailForm.sendAddr = "smtp.yandex.com";
          this.emailForm.getPort = "993";
          this.emailForm.sendPort = "465";
          break;
        case 1:
          this.emailForm.email = "@163.com";
          this.emailForm.getAddr = "imap.163.com";
          this.emailForm.sendAddr = "smtp.163.com";
          this.emailForm.getPort = "993";
          this.emailForm.sendPort = "465";
          break;
        case 2:
          this.emailForm.email = "@yeah.net";
          this.emailForm.getAddr = "imap.yeah.net";
          this.emailForm.sendAddr = "smtp.yeah.net";
          this.emailForm.getPort = "993";
          this.emailForm.sendPort = "465";
          break;
        case 3:
          this.emailForm.email = "@cnprussia.ru";
          this.emailForm.getAddr = "imap.yandex.ru";
          this.emailForm.sendAddr = "smtp.yandex.ru";
          this.emailForm.getPort = "993";
          this.emailForm.sendPort = "465";
          break;
        default:
          this.emailForm.email = "";
          this.emailForm.getAddr = "";
          this.emailForm.sendAddr = "";
          this.emailForm.getPort = "";
          this.emailForm.sendPort = "";
          break;
      }
    },
    //邮箱取消按钮
    cancel() {
      this.emailForm = JSON.parse(JSON.stringify(this.oldEmailForm));
      this.is_init = false;
      this.show_password = false;
      this.checkAll = this.emailForm.folder.length === this.folderList.length;
      this.isIndeterminate =
        this.emailForm.folder.length > 0 &&
        this.emailForm.folder.length < this.folderList.length;
    },
    //删除联系人
    delContact(id) {
      this.$confirm(this.$t("email.confirmToDelete"), this.$t("tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      }).then(() => {
        this.axios
          .post("/home/Index/emailContactsDel", {
            id: id,
          })
          .then((res) => res.data)
          .then((res) => {
            if (res.status != 1) {
              toMessage("error", res.msg);
              return;
            }
            toMessage();
            this.getContacts();
          })
          .catch(() => {});
      });
    },
    //编辑联系人
    editContact(index) {
      this.contact_email = JSON.parse(
        JSON.stringify(this.contactData[index].email)
      );
      this.contact_desc = JSON.parse(
        JSON.stringify(this.contactData[index].desc)
      );
      this.contact_id = JSON.parse(JSON.stringify(this.contactData[index].id));
      this.visible = true;
    },
    //新增联系人
    addContact() {
      this.contact_email = "";
      this.contact_desc = "";
      this.contact_id = "";
      this.visible = true;
    },
    //联系人编辑提交
    visibleSub() {
      if (this.contact_email.trim() == "") {
        this.$message(toMessage("error", this.$t("email.error2")));
        return;
      }
      let have_email = this.contactData.some((item) => {
        return (
          item.id != this.contact_id && this.contact_email.trim() == item.email
        );
      });
      if (have_email) {
        this.$message(toMessage("error", this.$t("email.error3")));
        return;
      }
      this.loading = true;
      this.axios
        .post("/home/Index/emailContactsPut", {
          contact_id: this.contact_id,
          contact_email: this.contact_email,
          contact_desc: this.contact_desc,
        })
        .then((res) => res.data)
        .then((res) => {
          this.loading = false;
          if (res.status != 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          this.$message(toMessage());
          this.visible = false;
          this.getContacts();
        })
        .catch(() => {});
    },
    //修改密码
    changePassword() {
      this.show_password = true;
      this.emailForm.password = "";
    },
    //提交
    submit() {
      let putInfo = JSON.parse(JSON.stringify(this.emailForm));
      putInfo.Secure = putInfo.Secure ? 1 : 0;
      putInfo.Domestic = putInfo.Domestic ? 1 : 0;
      if (putInfo.email.trim() == "") {
        this.$message(toMessage("error", this.$t("email.error4")));
        return;
      }
      if (this.show_password || this.is_init) {
        if (putInfo.password.trim() == "") {
          this.$message(toMessage("error", this.$t("email.error5")));
          return;
        }
      } else {
        delete putInfo.password;
      }
      let checkObj = {
        getAddr: this.$t("email.receivingServer"),
        getPort: this.$t("email.receivingServer") + " " + this.$t("email.port"),
        sendAddr: this.$t("email.sendingServer"),
        sendPort: this.$t("email.sendingServer") + " " + this.$t("email.port"),
      };
      for (const key in checkObj) {
        if (putInfo[key].trim() == "") {
          this.$message(
            toMessage("error", this.$t("email.error6") + checkObj[key])
          );
          return;
        }
      }
      if (!this.is_init && !putInfo.folder.length) {
        this.$message(toMessage("error", this.$t("email.error7")));
        return;
      }
      this.loading = true;
      this.axios
        .post("/home/Index/saveEmail", putInfo)
        .then((res) => res.data)
        .then((res) => {
          this.loading = false;
          if (res.status == 0) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          setTimeout(() => {
            this.cacheEmailToDb();
          }, 1);
          this.$message(toMessage("success", res.msg));
          this.is_init = false;
          this.show_password = false;
          this.getEmailInfo();
        })
        .catch(() => {
          this.loading = false;
          this.$message(toMessage("error", this.$t("email.error")));
          return;
        });
    },
    //静默缓存邮箱数据
    cacheEmailToDb() {
      this.axios.post("/home/index/cacheEmailToDb").then((res) => {
        res.data;
      });
    },
    saveEmailSign() {
      this.axios
        .post("/home/Index/saveEmailSign", {
          sign_desc: this.sendData.html,
        })
        .then((res) => res.data)
        .then((res) => {
          this.loading = false;
          if (res.status !== 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          this.$message(toMessage());
        })
        .catch(() => {});
    },
  },
  mounted: function () {
    this.editor = new E("#editor");
    this.editor.config.onchange = (html) => {
      this.sendData.html = html;
    };
    this.editor.config.menus = [
      "bold", // 粗体
      "fontSize", // 字号
      "fontName", // 字体
      "italic", // 斜体
      "underline", // 下划线
      "strikeThrough", // 删除线
      "foreColor", // 文字颜色
      "backColor", // 背景颜色
      "link", // 插入链接
      "list", // 列表
      "justify", // 对齐方式
      "quote", // 引用
      "image", // 插入图片
      "table", // 表格
      "undo", // 撤销
      "redo", // 重复
    ];
    // 自定义字体
    this.editor.config.fontNames = ["宋体", "微软雅黑"];
    // 多语言
    this.editor.config.lang = {
      设置标题: "title",
      正文: "p",
      链接文字: "link text",
      上传图片: "upload image",
      上传: "upload",
      创建: "init",
      字体: "fonts",
      文字颜色: "text color",
      背景色: "background color",
      设置列表: "settings list",
      对齐方式: "alignment",
      网络图片: "web picture",
      图片链接: "image link",
      链接: "link",
      插入表格: "insert table",
      插入: "insert",
      字号: "font size",
      有序列表: "ordered list",
      无序列表: "unordered list",
      行: "line",
      列: "column",
      靠左: "  left",
      居中: "center",
      靠右: " right",
      的表格: " ",
    };
    this.editor.create();
    this.editor.txt.html("");
  },
  created() {
    this.getContacts();
    this.getEmailInfo();
  },
};
</script>

<style scoped>
#email {
  padding: 28px !important;
  height: 100%;
  overflow: auto;
}
.el-dialog__body {
  height: 270px;
}

.dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-dialog__header {
  font-weight: 600;
  text-align: center;
}

.el-dialog__title {
  padding-top: 30px;
  display: block;
  padding-bottom: 10px;
}

.container {
  width: 1639px;
  margin: 0 auto;
  margin-top: 21px;
  display: flex;
  justify-content: space-between;
}

.w804 {
  width: 804px;
  height: 844px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 28px 48px;
  position: relative;
  overflow-y: auto;
}

.titH {
  text-align: center;
  font-size: 18px;
  margin-bottom: 28px;
  font-weight: 500;
}

.contact-titH {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-bottom: 28px;
  font-weight: 500;
  position: relative;
  height: 30px;
}

.contact-titH >>> .el-button {
  position: absolute;
  right: 0px;
}

.tit-s {
  font-size: 14px;
  font-weight: 400;
  width: 110px;
  text-align: right;
  color: rgba(51, 51, 51, 1);
}

.psw-span {
  margin-right: 30px;
  color: #333333;
  padding-top: 5px;
}

.signature-span {
  font-size: 16px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
}

.mt10 {
  margin-top: 10px;
}

.submit-box {
  width: 708px;
  height: 40px;
}

.big-btn {
  padding-left: 60px;
  padding-right: 60px;
}

.mr60 {
  margin-right: 60px;
}

.select-email {
  width: 150px;
}

.select-email >>> .el-input__inner {
  border: none;
}

.email-inner {
  flex: 1;
}

.email-out {
  display: flex;
  flex-direction: column;
  height: 730px;
}

.form-item:first-child {
  flex-direction: column;
}

.w50 {
  width: 50%;
}

.option-box {
  border: 1px solid #eee;
  padding: 10px 15px 10px;
  margin-top: -10px;
}

.mt68 {
  margin-top: 68px;
}

.all-option {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.all-option >>> .el-checkbox {
  margin-right: 1%;
  width: 49%;
}

.block-box {
  width: 100%;
}

.hand {
  cursor: pointer;
}
.col-editor {
  margin-bottom: 20px;
}
</style>